<template>
  <header class="fd-app-header-novo">
    <!--fd-app-header-novo-->
    <div class="container fd-app-header-novo-content d-flex justify-content-between align-items-center">
      <!--fd-app-header-novo-menu-->
      <div class="fd-app-header-novo-menu">
        <div class="fd-app-header-novo-menu-btn"
          v-b-toggle.sidebar-1
          >
          <b-icon-list font-scale="1.8"/>
        </div>
        <b-sidebar class="fd-app-header-novo-menu-sidebar" id="sidebar-1" backdrop shadow>
          <div class="d-flex justify-content-start align-items-center">
            <div class="ml-3">
              <div
                v-if="!fastUsuarioParametro.image"
                :style="{ backgroundImage: 'url(https://toussaint.concurseiro.com/css/images/avatar.svg)', width: '40px', height: '40px', margin: 'auto',  borderRadius: '50%', border: '2px solid #008BD0', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
              />
              <div
                v-else
                :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioParametro.image) + ')', width: '40px', height: '40px', margin: 'auto',  borderRadius: '50%', border: '2px solid #008BD0', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
              />
            </div>
            <small class="text-secondary ml-2">
              Olá, {{ nomeUsuario }}.
            </small>
          </div>
          <div class="list-group my-4">
            <a 
              v-if="$store.state.fastUsuarioAdminFast"
              :href="'/adminfast'"
              class="list-group-item list-group-item-action d-flex justify-content-between py-1"
              @click.prevent="$router.push('/adminfast')"
            >
              <span class="font-weight-bold text-danger"><b-icon-person-lines-fill />&nbsp;Admin FAST</span>
            </a>

            <a 
              v-if="$route.params.id_plataforma && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.aluno == 'S')"
              href="#"
              @click.prevent
              v-b-toggle.modo-aluno
              class="list-group-item list-group-item-action d-flex justify-content-between py-1"
            >
              <span class="font-weight-bold mt-1"><b-icon-person-fill />&nbsp;Modo Aluno</span>
              <a 
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >
                <b-icon-reply-all-fill />
              </a>
            </a>
            <b-collapse 
              v-if="$route.params.id_plataforma && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.aluno == 'S')" 
              :visible="$store.state.fastPermissoes.administrador == 'N' && $store.state.fastPermissoes.secretario == 'N' && $store.state.fastPermissoes.tesouraria == 'N' &&  $store.state.fastPermissoes.aluno == 'S' ? true : false"
              class="m-0" id="modo-aluno"
            >
              <a 
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')" 
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Minhas Turmas</span>
              </a>
              <a 
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-boletos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-boletos')" 
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Meus Boletos</span>
              </a>
              <a 
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/tutoriais'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/tutoriais')" 
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Tutoriais</span>
              </a>
              <div class="list-group-item py-1">
                <a
                  v-if="this.$store.state.fastQntdLayouts > 1 && $route.params.id_plataforma && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.aluno == 'S')"
                  href="#"
                  class="btn-novo btn-primary py-0 ml-4"
                  @click.prevent="alteraLayoutAluno()"
                >
                  Alterar Tema
                </a>
              </div>
            </b-collapse>

            <a 
              v-if="$route.params.id_plataforma && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S')"
              href="#"
              @click.prevent
              v-b-toggle.modo-secretaria
              class="list-group-item list-group-item-action d-flex justify-content-between py-1"
            >
              <span class="font-weight-bold mt-1"><b-icon-ui-radios />&nbsp;Modo Secretaria</span>
              <a 
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >
                <b-icon-reply-all-fill />
              </a>
            </a>
            <b-collapse 
              v-if="$route.params.id_plataforma && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S')" 
              class="m-0" id="modo-secretaria"
            >
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/alunos?secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/alunos?secretaria')"      
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Alunos</span>
              </a>
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/cursos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/cursos')" 
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Cursos</span>
              </a>
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/unidades-curriculares'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/unidades-curriculares')" 
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Unidades Curriculares</span>
              </a>
            </b-collapse>

            <a 
              v-if="$route.params.id_plataforma && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S'|| $store.state.fastPermissoes.secretario == 'S')"
              href="#"
              @click.prevent
              v-b-toggle.modo-financeiro
              class="list-group-item list-group-item-action d-flex justify-content-between py-1"
            >
              <span class="font-weight-bold mt-1"><b-icon-bank2 />&nbsp;Modo Financeiro</span>
              <a 
                :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >
                <b-icon-reply-all-fill />
              </a>
            </a>
            <b-collapse 
              v-if="$route.params.id_plataforma && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S'|| $store.state.fastPermissoes.secretario == 'S')"
              class="m-0" id="modo-financeiro"
            >
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria/emissao-boletos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria/emissao-boletos')"      
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Gestão de Recebíveis</span>
              </a>
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria/gestao-comissoes'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria/gestao-comissoes')"      
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Gestão de Comissões</span>
              </a>
            </b-collapse>

            <a 
              v-if="$route.params.id_plataforma && $store.state.fastPermissoes.professor == 'S'"
              :href="'/plataforma/' + $route.params.id_plataforma + '/professor'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
              class="list-group-item list-group-item-action py-1"
            >
              <span class="font-weight-bold"><b-icon-person-fill />&nbsp;Modo Professor</span>
            </a>

            <a 
              v-if="$route.params.id_plataforma && $store.state.fastPermissoes.administrador == 'S'"
              href="#"
              @click.prevent
              class="list-group-item list-group-item-action d-flex justify-content-between py-1"
              v-b-toggle.modo-admin
            >
              <span class="font-weight-bold mt-1"><b-icon-gear-fill />&nbsp;Modo Admin</span>
              <a 
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >
                <b-icon-reply-all-fill />
              </a>
            </a>
            <b-collapse 
              v-if="$route.params.id_plataforma && $store.state.fastPermissoes.administrador == 'S'"
              class="m-0" id="modo-admin"
            >
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/personalizar-plataforma'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/personalizar-plataforma')"      
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Plataforma</span>
              </a>
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/provas')"      
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Provas</span>
              </a>
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/tutoriais'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/tutoriais')"      
                class="list-group-item py-1"
              >
                <span class="font-weight-bold ml-4">Tutoriais</span>
              </a>
            </b-collapse>

            <a 
              v-if="$route.params.id_plataforma"
              :href="'/plataforma/' + $route.params.id_plataforma + '/meu-perfil'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/meu-perfil')"
              class="list-group-item list-group-item-action py-1"
            >
              <span class="font-weight-bold"><b-icon-person-circle />&nbsp;Meu Perfil</span>
            </a>
          </div>
          <div>
            <a
              href="#"
              class="btn-novo btn-primary ml-3 px-2 py-0"
              @click="logout"
            >
              Sair
            </a>
          </div>
        </b-sidebar>
      </div>
      <!--/fd-app-header-novo-menu-->

      <!--fd-app-header-novo-brand-->
      <div class="fd-app-header-novo-brand">
        <img
          :src="imageSiteLogoHorizontal"
          class="img-fluid"
          style="max-height: 22px"
        >
      </div>
      <!--/fd-app-header-novo-brand-->

      <!--fd-app-header-novo-perfil-->
      <div class="fd-app-header-novo-perfil d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center pc-novo">
          <div
            v-if="!fastUsuarioParametro.image"
            :style="{ backgroundImage: 'url(https://toussaint.concurseiro.com/css/images/avatar.svg)', width: '40px', height: '40px', margin: 'auto',  borderRadius: '50%', border: '2px solid #008BD0', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
          />
          <div
            v-else
            :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioParametro.image) + ')', width: '40px', height: '40px', margin: 'auto',  borderRadius: '50%', border: '2px solid #008BD0', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
          />
          <div class="text-secondary ml-1">
              <p> Olá, {{ nomeUsuario }} </p>
              <p>
                <a
                  :href="'/plataforma/' + $route.params.id_plataforma + '/meu-perfil'"
                  @click.prevent="$router.push(`/plataforma/${$route.params.id_plataforma}/meu-perfil`)"
                >
                  Meu perfil
                </a>
              </p>
          </div>
        </div>

        <div class="fd-app-header-novo-perfil-notify d-flex justify-content-center align-items-center pointer">
          <div class="fd-app-header-novo-perfil-notify-icon" @click.prevent :id="`popover-1-left`"><b-icon-bell font-scale="1.9"/></div>
          <span v-if="$store.state.fastMuralTotalAvisos" class="fd-app-header-novo-perfil-notify-badge badge rounded-pill bg-danger text-light">{{ fastMuralTotalAvisos }}</span>
        </div>
        <b-popover
          :target="`popover-1-left`"
          placement="bottomleft"
          title="Notificações"
          triggers="hover focus"
        >
          <span v-if="!fastMural.length">Nenhuma notificação</span>
          <div v-if="fastMural.length" class="fd-app-header-novo-perfil-notify-readall w-100 text-right pb-2">
            <a 
              href="#"
              @click.prevent="visualizaTodosAvisoMural()"
              class="text-secondary pl-2"
            >
             <small><b-icon-eye-fill/> Marcar todos como lidos</small>
            </a>
          </div>
          <li id="fd-app-header-novo-perfil-notify-list" 
            v-for="(aviso, index) in fastMural" :key="index"
            :class="{ 'fade': fastMuralRemoveItem === index, 'show': fastMuralRemoveItem !== index }"
          >
            <div>
              <p href="#" role="button"
                @click="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/mural').catch(()=>{})"
              >{{aviso.titulo_publicacao}}</p>
              <div class="d-flex justify-content-between">
                <a href="#" @click.prevent>
                  <small class="text-primary">{{ formataDataT(aviso.data_publicacao) }}</small>
                </a>
                <a 
                  href="#"
                  @click.prevent="removeAvisoMural(aviso)"
                  class="text-orange"
                >
                  <small>Marcar como lido <b-icon-circle-fill font-scale="1"/></small>
                </a>
              </div>
            </div>
          </li>
        </b-popover>
        <a
          href="#"
          class="btn-novo btn-primary pc-novo"
          @click="logout"
        >
          Sair
        </a>
      </div>
      <!--/fd-app-header-novo-perfil-->
    </div>
    <!--fd-app-header-novo-->
  </header>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import imageSiteLogoHorizontal from "@/assets/images/logo_horizontal.png";

export default {
  mixins: [methods],
  data: function () {
    return {
      imageSiteLogoHorizontal: "",
      nomeUsuario: this.retornaSessao(settings.fastSessaoUsuario).First_name,
      imageUsuario: this.retornaSessao(settings.fastSessaoUsuario).Image,
      // Parametro
      fastUsuarioParametro: {
        id_usuario: "",
        image: "",
      },
      // Mural
      fastMural: '',
      fastMuralRemoveItem: null,
      fastMuralLoading: ''
    };
  },
  mounted: function () {
    settings
      .fastLogoPrincipal()
      .then((e) => (this.imageSiteLogoHorizontal = e.default));
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
          .then(() => {
            this.getFastUsuarioParametro();
            this.getMuralPaginado();
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
  },
  methods: {
    logout() {
      sessionStorage.clear();
      location.reload();
    },
    async getFastUsuarioParametro() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_parametro/lista",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastUsuarioParametro = obj[0];
        } else {
          this.fastUsuarioParametro = {
            id_usuario: "",
            image: "",
          };
        }
        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getMuralPaginado() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            `api/fast_mural/lista_mural_paginado_nao_visualizado?PageIndex=1&PageSize=100&IdPlataforma=${this.$route.params.id_plataforma}&IdPessoa=${this.$store.state.fastPermissoes.id_pessoa}`,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();  
        this.fastMuralLoading = false
        if (json.TotalResults) {
          this.fastMural = json.Data
          this.$store.state.fastMuralTotalAvisos = json.TotalResults
          console.log('getMural',json)
        }       
      } catch (e) {
        console.log("Erro", e);
      }
    },
    visualizaAvisoMural(aviso){
      let obj = { Id_mural : aviso.id_mural, Id_plataforma : aviso.id_plataforma, Id_pessoa : this.$store.state.fastPermissoes.id_pessoa }
      this.promisePostFastApi(obj, "api/fast_mural_pessoa_visualizou/insere")
          .then(res => {
            console.log(res)
          })
          .catch(e => {
            console.error(e)
          })
    },
    removeAvisoMural(aviso){
      const indexRemover = this.fastMural.findIndex(item => item.id_mural === aviso.id_mural);
      this.fastMuralRemoveItem = indexRemover;

      if (indexRemover !== -1) {
        setTimeout(() => {
              this.fastMural.splice(indexRemover, 1);
              this.visualizaAvisoMural(aviso)
              this.$store.state.fastMuralTotalAvisos -= 1
              this.fastMuralRemoveItem = null;
            }, 500);
      }
    },
    visualizaTodosAvisoMural(){
      this.fastMural.forEach(aviso => {
        this.visualizaAvisoMural(aviso)
      });
      this.fastMural = []
      this.$store.state.fastMuralTotalAvisos = ''
    }
  },
  computed: {
    fastMuralTotalAvisos() {
      return this.$store.state.fastMuralTotalAvisos
    }
  },
};
</script>

<style scope>

</style>
